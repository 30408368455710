
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          TowerTech Scaffolding Pte Ltd
          <br />
          <i>reliable scaffolds provider</i>
          
        </p>
        <div className="sub-header">
        page under construction (pun intended)
      </div>

      </header>
      
    </div>
  );
}

export default App;
